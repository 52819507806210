@import '../colorVariables.scss';
@import '../mixins.scss';

// Application List View SCSS
.application-list-view {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  // @media (max-width: 768px) {
  //   width: 320px;
  // }

  .ui.header.application-list-header {
    background-color: #ffffff;
    height: 64px;

    .ui.icon.input > input {
      width: 100%;
    }

    .drop-down-element {
      display: flex;
      justify-content: right;

      .ui.selection.dropdown {
        //min-width: 100px !important;
        min-width: 100%;
      }
    }

    .ui.selection.dropdown {
      min-width: 110px;
      max-width: 100px;
      min-height: 32px;
      max-height: 32px;
      display: flex;
      align-items: flex-end;
    }
  }

  .application-list-element {
    max-height: 574px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    padding: 0px !important;

    .item.application-list-item {
      padding-left: 14px !important;
      padding-right: 14px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    .cus-img {
      width: 32px;
      height: 32px;
    }
    .cus-name {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #2563eb;
      @media (max-width: 768px) {
        margin-left: 12px;
      }
    }

    .vehicle-import {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: #2563eb;
    }

    .vehicle-import-bold {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #000;
    }

    .create-on {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: right;
      color: #767676;
      @media (max-width: 768px) {
        //margin-left: -30px;
        text-align: left !important;
        margin-left: 15px;
      }
    }

    .create-on-bold {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: right;
      color: #000;
    }
    .unique-no-badge {
      margin-right: 5px;
    }

    .unique-on-bold {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: right;
      color: $Primary;
    }

    .lodged-button {
      background-color: #f59e0b;
      width: fit-content;
      height: fit-content;
      padding: 6px !important;
      border-radius: 4px;
      gap: 10px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: white;

      &:hover {
        background-color: #f59e0b;
        color: white;
      }
    }

    .submitted-button {
      @media (max-width: 768px) {
        //margin-left: -30px;
      }
      background-color: #2563eb;
      width: fit-content;
      height: fit-content;
      padding: 6px !important;
      border-radius: 4px;
      gap: 10px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: white;

      &:hover {
        background-color: #2563eb;
        color: white;
      }
    }
  }

  .ui.segment.application-list-footer {
    display: flex;
    justify-content: flex-end;
    background-color: #f9fafb;
    height: 54px;
    .footer-content {
      cursor: pointer;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: #2563eb;
    }
  }

  .ui.segment.application-list-footer-with-pagination {
    display: flex;
    justify-content: center;
    background-color: #f9fafb;
    height: 54px;
    .footer-content {
      cursor: pointer;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: #2563eb;
    }
  }

  .ui.segment.application-list-footer-with-pagination {
    display: flex;
    justify-content: center;
    background-color: #f9fafb;
    height: 54px;
    .footer-content {
      cursor: pointer;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: #2563eb;
    }
  }

  .ui.segment.application-list-footer-with-pagination {
    display: flex;
    justify-content: center;
    background-color: #f9fafb;
    height: 54px;
    .footer-content {
      cursor: pointer;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: #2563eb;
    }
  }

  .application-list-element::-webkit-scrollbar {
    width: 0px;
  }

  .application-list-element::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

// Compliance Application
.compliance-application {
  .update-application-form-layout {
    height: fit-content !important;
    @media (max-width: 768px) {
      height: fit-content !important;
    }
  }

  .formik-custom-field input {
    line-height: 0px;
    background-color: #ffffff !important;
  }

  .submit-button {
    .ui.secondary.button {
      display: flex !important;
      align-items: flex-end !important;
      justify-content: flex-end !important;
      color: #ffffff !important;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
    .ui.button {
      padding: 5px 10px;
    }
  }
}

// Vehicle Import Application
.vehicle-import-application {
  .update-application-form-layout {
    height: fit-content !important;
    @media (max-width: 768px) {
      height: fit-content !important;
    }
  }

  .formik-custom-field input {
    line-height: 0px;
    background-color: #ffffff !important;
  }

  .submit-button {
    .ui.secondary.button {
      display: flex !important;
      align-items: flex-end !important;
      justify-content: flex-end !important;
      color: #ffffff !important;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
    .ui.button {
      padding: 5px 10px;
    }
  }
}

// Update Application SCSS
.update-application {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .ui.header.update-application-header {
    background-color: #ffffff;
    height: 53px;

    @media (max-width: 768px) {
      margin-bottom: 40px;
    }

    .update-application-title {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.10000000149011612px;
      text-align: left;
      color: #3b3b3b;
    }

    .submitted-button {
      @media (max-width: 768px) {
        //margin-left: -30px;
      }
      background-color: #2563eb;
      width: fit-content;
      height: fit-content;
      padding: 6px !important;
      border-radius: 4px;
      gap: 10px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: white;

      &:hover {
        background-color: #2563eb;
        color: white;
      }
    }

    .paid-button {
      background-color: #00920f;
      width: fit-content;
      height: fit-content;
      padding: 6px 10px !important;
      border-radius: 4px;
      gap: 10px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: white;

      &:hover {
        background-color: #00920f;
        color: white;
      }
    }

    .un-paid-button {
      background-color: #ef4444;
      width: fit-content;
      height: fit-content;
      padding: 6px 10px !important;
      border-radius: 4px;
      gap: 10px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: white;

      &:hover {
        background-color: #ef4444;
        color: white;
      }
    }

    .view-receipt-layout {
      display: flex;
      justify-content: right;
      align-items: center;
      .view-receipt {
        cursor: pointer;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: #2563eb;
        text-decoration: underline;

        > :not(Icon) {
          text-decoration: none;
        }
      }
    }
  }

  .update-application-form-layout {
    height: fit-content;
    padding: 0px !important;
    padding-bottom: 25px !important;
    @media (max-width: 868px) {
      margin-top: 90px;
      padding-left: 15px !important;
      padding-right: 10px !important;
    }

    .ui.grid.update-application-form {
      .row .column {
        @media only screen and (max-width: 768px) {
          width: 100% !important;
          padding-bottom: 5px !important;
        }
      }
      margin: 0px;

      .custom-label {
        margin-bottom: 4px;
      }

      .sub-topic {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        font-weight: 600 !important;
      }

      .last-input-row {
        padding-bottom: 5rem !important;
        padding-top: unset !important;
      }

      .choose-button {
        display: flex;
        justify-content: center;
        align-items: center;
        .upload-icon {
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          color: #2563eb;
        }
        .upload-button {
          background-color: transparent;
          color: #2563eb;
        }

        .ui.secondary.button {
          color: #ffffff !important;
          font-family: Inter;
          font-size: 10px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: center;
        }
        .ui.button {
          padding: 5px 2px;
        }
      }

      .import-approval-layout {
        margin-top: 20px !important;

        .vehicle-approval-container {
        }

        .vehicle-approval-container.has-document {
          height: 212px;
          width: 212px;
        }

        .vehicle-approval-container:not(.has-document) {
          height: auto;
          width: auto;
        }
        .vehicle-approval-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px;
          border: 3px dotted #e5e7eb;

          .document-preview-container {
            position: relative;
          }

          .document-delete-button {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 10px;
            margin-right: 10px;
            z-index: 1;
            width: 30px;
            height: 30px;
            color: #ef4444;
            background-color: #ffffff;
            border: 1px solid #e5e7eb;
            cursor: pointer;
          }

          .ui.button.document-delete-button {
            padding: 0px !important;
            .icon {
              margin: 0px !important;
            }
          }
        }
      }

      .input-label {
        color: #000000;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }

      .small-label-gray {
        color: #6b7280;
        font-size: 12px;
        font-weight: 200;
      }

      .small-label-black {
        color: #000000;
        font-size: 12px;
        font-weight: 200;
      }

      .large-label-black {
        color: #000000;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 8px;
      }

      .label-blue {
        cursor: pointer;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: #2563eb;
      }

      .circle-container {
        width: 24px;
        height: 24px;
        background-color: #f3f4f6;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
      }

      .inline-container {
        display: flex;
        align-items: center;
      }

      .vehicle-doc-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 212px;
        width: 212px;
        padding: 15px;
        border: 3px dotted #e5e7eb;

        .document-preview-container {
          position: relative;
        }

        .document-delete-button {
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 10px;
          margin-right: 10px;
          z-index: 1;
          width: 30px;
          height: 30px;
          color: #ef4444;
          background-color: #ffffff;
          border: 1px solid #e5e7eb;
        }

        .ui.button.document-delete-button {
          padding: 0px !important;
          .icon {
            margin: 0px !important;
          }
        }
      }

      .ui.grid.row.replace-button {
        padding-top: 0px !important;
      }

      .vehicle-image-container {
        display: flex;
        overflow-x: auto;
        align-items: center;
        height: 167px;
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
        border: 3px dotted #e5e7eb;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer/Edge */
        cursor: pointer;
        .empty-image-container {
          display: flex;
          justify-content: center;
        }

        .document-delete-button {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          margin-top: 10px;
          z-index: 1;
          width: 30px;
          height: 30px;
          color: #ef4444;
          background-color: #ffffff;
          border: 1px solid #e5e7eb;
        }

        .image-item {
          position: relative;
          margin: 5px; /* Add margin for spacing between images */
        }

        .vehicle-image {
          max-width: 100%;
          height: auto;
          /* Add any additional styling for the image */
        }
      }

      .vehicle-image-container::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
      }

      .vehicle-image {
        max-width: 180px;
        max-height: 135px;
        min-width: 180px;
        min-height: 135px;
        margin-right: 15px;
      }

      .report-type-drop {
        margin-bottom: 0px !important;
      }

      .ui.selection.dropdown.custom-drop-down {
        min-width: 266px !important;

        @media only screen and (max-width: 1200px) {
          min-width: 195px !important;
        }
        > .text > .flag {
          margin-right: -1.2rem;
        }
        .menu > .item > .flag {
          margin-right: -1.2rem;
        }
      }

      .date-of-arrival {
        margin-top: 8px !important;
        margin-bottom: 0px !important;
      }

      .formik-custom-field {
        margin-top: 10px !important;
        margin-bottom: 0px !important;
      }

      .formik-custom-field label {
        color: #000000 !important;
        font-family: Inter !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        padding-bottom: 5px !important;
      }

      .formik-custom-field input {
        line-height: 0px;
        background-color: #fafafa;
      }

      .plan-input-label {
        color: $black !important;
      }

      .date-range-container {
        input[type='text'] {
          @include Body-Default;

          &:focus {
            border: none !important;
            box-shadow: none !important;
          }
        }

        .date-range-chart {
          border-color: #c3d3e6 !important;
        }
      }

      .required-stars:after {
        content: ' *';
        color: red;
        font-size: 18px;
      }
    }
  }

  .update-application-footer {
    .remove-button {
      .remove-button-text {
        display: block;
      }

      .remove-button-icon {
        display: none;
      }

      @media only screen and (max-width: 767px) {
        .remove-button-text {
          display: none;
        }

        .remove-button-icon {
          display: block;
        }
      }
      .ui.basic.red.button {
        color: #c62828 !important;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        height: fit-content;
      }
      .ui.button {
        padding: 5px 10px;
      }
    }

    // .download-button {
    //   .ui.basic.button {
    //     color: $white !important;
    //     font-family: Inter;
    //     font-size: 14px;
    //     font-weight: 500;
    //     line-height: 20px;
    //     letter-spacing: 0px;
    //     text-align: left;
    //     background: $black !important;
    //   }
    //   .ui.button {
    //     padding: 5px 10px;
    //   }
    // }

    // styles.scss

    .download-button {
      .download-all-button {
        display: block;
      }

      .ui.basic.button {
        color: $white !important;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        background: $black !important;
      }
      .ui.button {
        padding: 5px 10px;
      }

      .download-icon-button {
        display: none;
      }

      @media only screen and (max-width: 767px) {
        .download-all-button {
          display: none;
        }

        .ui.basic.button {
          font-size: 12px !important;
          width: max-content !important;
        }

        .download-icon-button {
          display: block;
        }
      }
    }

    .complete-button {
      .ui.secondary.button {
        color: #ffffff !important;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
      }
      .ui.button {
        padding: 5px 10px;
      }
    }
  }
}

// Compliance Application SCSS

// custom segment
.custom-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.031rem solid #d6d6d6 !important;

  .segment-title {
    color: var(--Text-Primary, #000);
    /* Body - Large - Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.1px;
  }
  .segment-heading {
    display: flex;
    align-self: center;
    @include Body-Default-Semibold();
  }

  .projected-activity-modal-toggle {
    color: $gradientRight !important;
  }

  .projected-activity-modal-heading {
    @include Body-Small($gradientRight);
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .segment-heading-custom {
    display: flex;
    align-self: center;
    margin: 0.313rem 0 0 0;
    @include Body-Default;
  }

  .segment-icon {
    margin-right: 0.75rem !important;
  }

  .segment-icon-cursor {
    margin-right: 0.75rem !important;
    border: none;
    cursor: pointer !important;
  }

  .notification-link {
    margin-left: 11.25rem !important;
    @include Body-Small($Primary);
  }
}

.activity-participant-sub {
  @include Body-Small($Text-Secondary);
  margin-top: -0.625rem !important;
}

.header-title-flex {
  display: flex !important;
  flex-direction: column !important;
  margin-left: -0.438rem;
}

.custom-segment-without-border-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .segment-heading {
    display: flex;
    align-self: center;
    @include Body-Small($info);
    margin: 0.313rem 0;
  }

  .segment-heading-custom {
    display: flex;
    align-self: center;
    @include Body-Small($Text-Secondary);
    margin: 0.313rem 0 0 0;
  }

  .segment-icon {
    margin-right: 0.75rem !important;
  }

  .segment-icon-cursor {
    margin-right: 0.75rem !important;
    cursor: pointer !important;
  }

  .notification-link {
    margin-left: 11.25rem !important;
    @include Body-Small($Primary);
  }
}

@media only screen and (max-width: 101.5rem) {
  .info-pulse {
    margin-left: 0.625rem !important;
    display: block;
    cursor: pointer;
    animation: pulse 2s infinite;
    background-color: $gradientRight;
    color: white;
  }

  .info-pulse-close {
    margin-left: 0.625rem !important;
    display: block;
    cursor: pointer;
    animation: pulse 2s infinite;
    background-color: $gradientRight;
    color: white !important;
  }
}

.info-pulse {
  display: block;
  cursor: pointer;
  animation: pulse 2s infinite;
  background-color: $gradientRight;
  color: white;
}

.info-pulse:hover {
  animation: none;
}

.browse-file-width-button {
  width: 100% !important;
}

.info-pulse-close {
  display: block;
  cursor: pointer;
  animation: pulse 2s infinite;
  background-color: $gradientRight;
  color: white !important;
}

.info-pulse-close:hover {
  animation: none;
  color: white !important;
}

.info-window {
  background-color: white;
  height: auto;
  width: auto;
  max-width: 500px;
  min-height: 6.25rem;
  border-radius: 0.313rem;
  padding: 0.938rem;

  .info-description {
    flex: 1;
    @include Body-Small($info);
    text-align: center;
  }

  .info-button {
    flex: 1;
    @include Body-Default-Semibold($gradientRight);
    padding: 0.938rem 0;
    text-align: center;
    width: 100%;
  }
}

.ui.negative.message {
  background-color: $Error-light;
  .notification-title {
    @include Body-Default-Semibold($Error);
    padding-bottom: 1rem !important;
  }

  .notification-intro {
    @include Body-Default($Error);
  }
}

.user-pro-text {
  text-align: left;
  @include Body-Default-Semibold($info);
  cursor: pointer;
}

.accordion-ev {
  border: 0.063rem solid #ef8123 !important;
  border-radius: 0.25rem !important;
  border-top: 0.063rem solid #ef8123 !important;
}

.accordion-ev:hover {
  background: rgba(239, 129, 35, 0.1) !important;
}

.show-question-ac {
  background: #fafafa !important;

  box-sizing: border-box !important;
  border-radius: 0.25rem !important;
  border-bottom: none !important;
}

.user-profile-dropdown {
  .ui.dropdown .menu > .item {
    border-top: 0.063rem solid #e0e0e0;
  }
}

.user-profile-drop {
  .img-wid {
    object-fit: cover;
  }

  .ui.mini.image,
  .ui.mini.images .image,
  .ui.mini.images img,
  .ui.mini.images svg {
    width: 2.188rem !important;
    height: 2.188rem !important;

    border: 0.031rem solid #e0e0e0;
    border-radius: 0.25rem;
    padding: 0.25rem;
  }
}

.drop-in-userName {
  .img-wid {
    object-fit: cover;
  }

  .ui.mini.image,
  .ui.mini.images .image,
  .ui.mini.images img,
  .ui.mini.images svg {
    width: 46.67px !important;
    height: 46.67px !important;
  }
}

@media only screen and (min-width: 101.5rem) {
  .user-profile-drop {
    .img-wid {
      margin-bottom: 0.5rem;
    }
  }
}

.dropdown-adjust {
  display: flex;
  flex-direction: row;

  .drop-in-userName {
    width: auto;
    cursor: default;
    padding: 0rem 0.313rem 0rem 0rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    .img-wid {
      object-fit: cover;
    }

    .user-pro-text {
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      font-family: $fontFamily;
      margin-left: 0.625rem;
      font-style: normal;
      font-weight: 600;
      font-size: $Body-Default;
      line-height: 1.5rem;
    }
  }

  .user-profile-drop {
    display: flex;
    flex-direction: row;
    margin-top: 0.938rem;
    margin-right: $spacing-xl;
    justify-content: center;
    align-items: center;

    .name-wid {
      margin: auto;
      margin-left: 0.938rem;
    }

    .img-wid {
      object-fit: cover;
    }

    .name {
      @include Body-Default-Semibold;
    }

    .icon-re {
      margin: auto;
      margin-left: 0.5rem;
    }
  }

  .ui.dropdown .menu > .item {
    .text-color {
      @include Body-Default;
    }
  }

  .ui.dropdown .menu > .item:hover {
    background-color: rgba(239, 129, 35, 0.1) !important;

    .text-color {
      color: $gradientRight;
    }
  }
}

.user-info {
  i.large.icon {
    font-size: 1.2em;
    padding-bottom: 22px !important;
  }

  display: flex;
  flex-direction: column;

  .user-pro-text {
    text-align: left;
    @include Body-Default-Semibold;
    cursor: pointer;
    margin: 0rem;
  }

  .user-sub-text {
    @include Body-Small($Gray-70);
  }
}

.av-upload {
  .upload-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .row {
      margin: 0rem !important;
    }
  }

  .upload-area {
    display: flex;
    flex: 1;
    flex-direction: column;

    input[type='file'] {
      display: none;
    }

    .custom-file-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.857rem;
      width: 8.75rem;
      border: 0.063rem solid $gradientRight;
      border-radius: 0.25rem;
      background-color: $white;
      cursor: pointer;
      @include Body-Small($gradientRight);
      margin-top: 0.938rem !important;
    }

    .custom-file-upload:hover {
      background-color: $gradientRight;
      color: $white !important;
    }

    .info-sec {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
    }
  }

  .profile-picture {
    border-radius: 0.25rem;
    width: 9.375rem !important;
    height: 9.375rem !important;
  }

  .upload-image-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 2;

    .area {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      overflow: hidden;
    }

    img {
      max-height: 90% !important;
      max-width: initial;
    }
  }

  @media only screen and (max-width: 767px) {
    .upload-container {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .upload-image-area {
      margin-top: 0.625rem;
    }
  }
}

.browse-area {
  display: flex;
  flex-direction: column;

  .browse-container {
    display: flex;
    flex-direction: row;
  }

  .clear-browse {
    display: flex;
    align-items: center;
    color: $gradientRight;
    font-family: $fontFamily;
    font-size: $Body-Small;
    font-weight: 600;
    line-height: 1.063rem;
    text-align: center;
    padding-left: 0.625rem;
    padding-top: 0.313rem;
    cursor: pointer;
  }

  .clear-browse-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gradientRight;
    font-family: $fontFamily;
    font-size: $Body-Small;
    font-weight: 600;
    line-height: 1.063rem;
    text-align: center;
    padding-left: 0.625rem;
    padding-top: 0.313rem;
    cursor: pointer;
    margin-top: 0.625rem;
  }
}

.ui.basic.modal > .image-view {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.basic.modal > .image-view-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-inputs {
  font-size: $Body-Small !important;
}

@media only screen and (max-width: 767px) {
  .ui.grid > .profile-row {
    margin-left: 32px !important;
    margin-right: 41px !important;
  }
}

@media only screen and (max-width: 767px) {
  .ui.grid > .profile-row {
    margin-left: $spacing-xl !important;
    margin-right: 2.563rem !important;
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .main-content {
    margin-left: 0 !important;
  }
}

.issues-box {
  padding: 1rem $spacing-xl 1rem $spacing-xl;
  background-color: white;

  .padding-issue {
    background: #ffebee;
    border: 0.031rem solid #d50000;
    border-radius: 0.25rem;
    padding-top: 1rem;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }

  ul {
    font-size: $Body-Small;
    color: #d50000;
  }
}

.issues-box-info {
  padding: 1rem $spacing-xl 1rem $spacing-xl;
  background-color: white;

  .padding-issue {
    background: #dff0ff;
    color: $Info;
    border-radius: 0.25rem;
    border: 0.031rem solid $Info;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }

  ul {
    font-size: $Body-Default !important;
    color: $Primary;
  }
}

.info-box {
  padding: 1.25rem;
  padding-left: 1.875rem;
  background-color: #dff0ff;
  color: $complete;

  p {
    font-size: $Body-Small;
    font-weight: bold;
  }
}

.survey-gap {
  margin-bottom: 1.25rem;
  font-weight: normal;
}

.survey-message {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.custom-gen-questions {
  margin-top: 0.313rem !important;

  .form-group {
    margin-bottom: 0 !important;
    font-weight: normal;
  }
}

.custom-gen-questions-textarea-style {
  min-height: 152px !important;
  border-radius: 0.5rem !important;
  padding-bottom: 5.625rem !important;
}

@media only screen and (min-width: 992px) {
  .custom-gen-questions-textarea-style {
    min-width: 817px !important;
  }
}

@media only screen and (min-width: 1130px) {
  .custom-gen-questions-textarea-style {
    min-width: 940px !important;
  }
}

.bold-text {
  font-weight: bold;
}

.back-button-segment {
  color: black;
  margin-right: 0.313rem;
}

.slider-margin {
  margin: 3.125rem 0rem 3.125rem 0rem;
}

.slider-info-text {
  display: flex;
  justify-content: space-between;
  width: 100% !important;

  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 200% */
  text-align: center !important;
  color: #000000 !important;
}

.logo-container {
  display: flex;
  justify-content: center;

  .ui.small.image,
  .ui.small.images .image,
  .ui.small.images img,
  .ui.small.images svg {
    width: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .logo-container {
    display: flex;
    justify-content: center;

    .ui.small.image,
    .ui.small.images .image,
    .ui.small.images img,
    .ui.small.images svg {
      width: auto !important;
      height: 53.4.688rem !important;
    }
  }
}

.notify-message {
  margin-top: 0.625rem;
  text-align: left;

  .collapse {
    &:not(.show) {
      display: inline !important;
    }
  }
}

.notification-btn {
  display: flex;
  padding-top: 0.625rem;
  justify-content: flex-start !important;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  text-decoration-line: underline;
  color: #d50000;

  &:hover {
    cursor: pointer;
  }
}

.hidden-postcode .invalid-feedback {
  margin-top: -0.625rem;
}

.number-participants-div {
  display: flex;
  flex-direction: column;

  .number-participants-input {
    height: 2.857rem;
    min-width: 370px;
    width: 370px;
    margin-bottom: 0.938rem;
    border-radius: 0.5rem !important;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Small;
    line-height: 1.25rem;
    /* identical to box height, or 143% */

    color: #000000;
  }

  .number-participants-disabled {
    input {
      background: #eaecef;
    }
  }
}

.disabled-eval-plan {
  opacity: 0.5;
}

.disabled-eval-edit-plan {
  opacity: 0.5;
  cursor: not-allowed;
}

.invalid-city {
  border-color: red !important;
}

.invalid-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media only screen and (max-width: 767px) {
  .nfheader {
    font-size: 8.125rem !important;
    line-height: 160px !important;
  }
}

.header-fixed {
  position: sticky;
  top: 0;
}

.rating-css {
  width: 60%;
}

.rating-css span {
  width: 10%;
}

.full-rating {
  background: #ef8123;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  max-height: 3rem;
  max-width: 3rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 4vw;
  height: 4vw;
}

.empty-rating {
  background: #ffffff;
  border: 0.063rem solid #ea8c00;
  box-sizing: border-box;
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 3rem;
  max-width: 3rem;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  color: #ea8c00;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .rating-css {
    width: 100%;
  }

  .slider-info-text {
    width: 100%;

    font-size: $Body-Small;
  }

  .slider-margin {
    margin: 0.625rem 0rem;
  }

  .eight {
    width: 100%;
  }
}

.not-found {
  display: flex;
  height: 100vh;
  padding: 0vh 40vh;
  justify-content: center;
  align-items: center;
  background-color: white;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .nfheader {
      color: $info;
      font-family: $fontFamily;
      font-size: 12.5rem;
      font-weight: 600;
      line-height: 250px;
      text-align: center;
    }

    .nfdetails {
      color: $info;
      font-family: $fontFamily;
      font-size: $h6;
      line-height: 2.125rem;
      text-align: center;
    }

    .ui.button.nfbutton {
      margin-top: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.125rem;
      width: 277px;
      background-color: $Primary;
      color: white;
      border-radius: 0.25rem;
      box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
      font-family: $fontFamily;
      font-size: $Body-Default;
      font-weight: bold;
      line-height: 1.375rem;
      text-align: center;
    }

    .ui.button.nfbutton:hover,
    .ui.buttons.nfbutton .button:hover {
      background-color: $Primary-lighter;
      color: $white;
    }
  }
}

.permission-denied {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80vh;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .status {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 11.25rem;
      letter-spacing: 15.98px;
      color: #3b3b3b;
    }

    .header {
      margin-top: 5rem;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: $h4;
      color: #3b3b3b;
    }

    .note {
      text-align: center;
      margin-top: 1.25rem;
      margin-left: 12.5rem;
      margin-right: 12.5rem;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Large;
      color: #3b3b3b;
    }
  }
}

.ui.labeled.icon.menu {
  text-align: justify !important;
}

tbody {
  tr {
    td {
      border-bottom: 0.063rem solid $background;
      font-size: $Body-Small;
    }

    .more-height {
      height: 9.375rem !important;
    }

    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 6.25rem;

      p {
        font-size: $Body-Small;
      }
    }

    .no-data:hover {
      background-color: none !important;
    }
  }
}

.ui.nav-image.image,
.ui.nav-image.images .image,
.ui.nav-image.images img,
.ui.nav-image.images svg {
  width: 1.5rem;
  height: auto;
}

.ui.nav-image-resize.image {
  width: 1.75rem;
  height: auto;
}

.mob-toggle-container {
  display: flex !important;
  align-items: center;
}

.no-padding {
  padding: 0 !important;
}

.disabled-item {
  padding: 0.688rem 1rem;
  pointer-events: none;
}

.public-error-icon {
  margin-bottom: 26px;
}

.locked-activity {
  padding: 1.25rem 0rem;
}

.rating-css {
  width: 60%;
}

.rating-css span {
  width: 10%;
}

.full-rating {
  background: #ef8123;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  max-height: 3rem;
  max-width: 3rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 4vw;
  height: 4vw;
}

.empty-rating {
  background: #ffffff;
  border: 0.063rem solid #ea8c00;
  box-sizing: border-box;
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 3rem;
  max-width: 3rem;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  color: #ea8c00;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .rating-css {
    width: 100%;
  }

  .slider-info-text {
    width: 100%;

    font-size: $Body-Small;
  }

  .slider-margin {
    margin: 0.625rem 0rem;
  }
}

.see-more-see-less-toggle {
  color: $gradientRight !important;
  font-weight: 400 !important;
  cursor: pointer;
  width: fit-content;
}

.disbled-comp {
  opacity: 0.9 !important;
  color: rgba(40, 40, 40, 0.9) !important;
  cursor: not-allowed !important;
}

.disabled-padding {
  .ui.segment {
    padding: 0rem !important;
    margin-left: 200px;
  }
}

.segment-change {
  margin-top: -8px;
  margin-bottom: -10px;
}

.segment-change1 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.sub-child-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// react-bootstrap-daterangepicker styles
.date-range-container {
  // daterangepicker container area with label
  display: flex;
  flex-direction: column;
}

.date-range-chart {
  // react-bootstrap-daterangepicker custom component
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 40px !important;
  border: 0.063rem solid rgba(34, 36, 38, 0.15);
  border-radius: 0.5rem !important;

  .date-range-chart-date {
    font-size: $Body-Small !important;
    padding: 0.375rem 0.75rem;
  }

  // remove bootstrap default styles (border/outline styles)
  input {
    border: 0;
    background-color: transparent !important;
    box-shadow: #66afe9;
    width: 92% !important;
  }

  input:focus {
    border: 0;
    border-color: transparent;
    outline: 0 none;
  }

  .calendar {
    margin-bottom: 0.29em;
  }
}

.msg {
  color: var(--gray-500, #6b7280);
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.msg-action,
.msg-action:hover {
  color: #ef8123;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: $Body-Small;
  line-height: 1.25rem;
  text-align: right;
  cursor: pointer;
}

.project-partner-minified-txt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Default;
  line-height: 20px;
  color: #ef8123;
  cursor: pointer;
}

.formik-custom-multiple-field {
  margin-top: 20px;
  margin-bottom: 1rem;
  width: 100%;
}

.formik-custom-field {
  margin-top: 20px;
  margin-bottom: 1rem;
  width: 100%;

  label,
  .label {
    @include Body-Small($Text-Secondary);
    display: inline-block;
  }

  .required-stars:after {
    content: ' *';
    color: red;
    font-size: 14px !important;
  }

  .custom-radio {
    label {
      display: flex !important;

      input {
        margin-right: 5px !important;
      }
    }
  }

  .ui.input {
    width: 100% !important;
  }

  .ui.selection.dropdown {
    width: 100% !important;
  }

  .ui.scrolling.dropdown .menu {
    max-height: 13rem !important;
  }

  .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .optionGroupText {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .info-text {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 150% !important;
    color: #8c8c8c !important;
  }
}
.change-pw-btn {
  padding-top: 100px;
}
.custom-sidebar {
  position: fixed;
  height: 100%;
  overflow-y: auto;
  background-color: white;
  overflow-x: hidden;
}
/* dashboard.scss */

@media only screen and (max-width: 768px) {
  .description-box {
    margin-top: -40px;
  }
  .dashboard-card-content {
    width: 10px;
  }
}

.duration-drop-down {
  .ui.dropdown {
    color: #767676;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ui.dropdown .menu > .item {
    color: #767676;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.password-fail {
  font-style: normal;
  font-weight: 400;
  font-family: $fontFamily;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
}

.password-passed {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #05944f;
}

.slider-info-muted {
  display: flex;
  justify-content: space-between;
  width: 100% !important;

  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 200% */
  text-align: center !important;
  color: $subLabel !important;
}

//ManagementSummary
.management-summary {
  .header-container {
    background: $background !important;
    border-bottom: 0.5px solid #e0e0e0;
    width: -webkit-fill-available;

    .row.header-margin {
      margin: 1.71429rem 2.28571rem !important;
    }

    .flex-start {
      @include display-flex(center, flex-start);
      flex-direction: column;
    }

    .title {
      @include Body-Default($Gray-70);
      margin-bottom: 0.571428571rem;
    }
    .content {
      @include Body-Default();
      margin-bottom: 0.571428571rem;
    }
  }

  .sub-header-container {
    .row.subheader-margin {
      margin: 2.28571rem 2.28571rem 1.71429rem 2.28571rem !important;
      display: flex;
      align-items: flex-end;
    }

    .flex-end {
      display: flex;
      justify-content: flex-end;
    }

    .content {
      @include Body-Small();
      display: flex;
      align-items: center;
      .count {
        @include Body-Default();
        margin-left: 2.28571rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .activity-overview-alignment {
      display: unset !important;
    }
  }
  .activity-overview-alignment {
    display: flex;
  }
  .activity-name {
    margin-right: $spacing-md;
  }
}

.table-with-inside-header {
  tr {
    border-bottom: 1px solid #e0e0e0;
  }
  .table-heading td {
    padding-top: 0.571428571rem !important;
    padding-bottom: 0.571428571rem !important;
    background: $Gray-10 !important;
    @include Body-Default-Semibold($Gray-70);
  }
  .table-column-header th {
    padding-top: 0.571428571rem !important;
    padding-bottom: 0.571428571rem !important;
  }

  .subtitle {
    @include Body-Default($Gray-70);
  }
}
.sub-data-table {
  background: #fafafa;
}
.sub-first-column {
  margin-left: 40px;
}
.partner-total-column {
  color: black;
}

.arrow-first-column {
  display: flex;
  flex-direction: row;
}
.multi-save-button {
  display: flex;
  padding: 14px 13px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  margin-top: -28px;
  flex-direction: row-reverse;
  color: $gradientRight;
  background: $white;
}

.formik-custom-multiple-field {
  .ui.pointing.upward.dropdown .menu,
  .ui.top.pointing.upward.dropdown .menu {
    top: auto !important;
    bottom: 100% !important;
    /* margin: 0 0 0.78571429rem; */
    border-radius: 0.28571429rem;
  }

  .ui.selection.active.dropdown .menu {
    border-color: $white !important;
    box-shadow: none !important;
  }

  .text-empty-info {
    font-family: 'Inter', sans-serif;
    font-size: $Body-Small !important;
    font-weight: 400 !important;
    line-height: 1.142857143rem !important;
    color: $Error !important;
  }
}

// .sort-order-style {
//   margin-left: 0px;
//   width: 300px;
//   white-space: nowrap; // Prevents wrapping to the next line
// }
@media (max-width: 600px) {
  /* Adjust this value to the point where the layout breaks */
  .sort-order-style {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    width: 300px;
    white-space: nowrap;
  }
}
.not-verified-email {
  color: #8c8c8c !important;
  font-size: 12px;
  font-weight: 400;
}
.formik-permission-table-custom-border {
  border: 1px solid $Gray-40;
  border-radius: 0.571rem;
  @include set-margin(0.5rem, 0, 2.051rem, 0);
  width: 100%;
}

.formik-permission-table-custom {
  border: none !important;
  border-radius: 0.571rem !important;
  thead {
    tr {
      th {
        background-color: #ffffff !important;
        border: none !important;
        border-radius: 0.5rem !important;
        padding-top: 1rem !important;
      }
      th:first-child {
        padding-left: 1rem !important;
      }
    }
  }

  tbody {
    tr {
      padding: 0rem !important;

      td {
        padding: 1rem 0rem 0rem 1rem !important;
        border: 0rem !important;
      }

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.formik-permission-activity-section-cell {
  margin-left: 0.938rem !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  /* identical to box height, or 143% */
  color: #000000;
}

.no-feature-warning-box {
  background-color: white;
  margin: 1rem 0;
  .padding-issue {
    background: $Info-light;
    border: 0.031rem solid $Info;
    border-radius: 0.25rem;
    padding: 1rem;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }

  ul {
    font-size: $Body-Small;
    color: #d50000;
  }
}

.ui.tiny.pagination.menu {
  box-shadow: none !important;
  border: none !important;

  .item {
    position: initial !important;
    border: 0.0625rem solid #dfe3e8 !important;
    color: #000000 !important;
    border-radius: 0.25rem !important;
    padding: 0.625rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0rem 0.25rem !important;

    &.active {
      border: none !important;
      border: 0.0625rem solid $Primary !important;
      color: $Primary !important;
      background-color: $white !important;
    }

    &:hover {
      background-color: $Primary-lighter !important;
      border: 0.0625rem solid $Primary !important;
      color: $Primary !important;
    }
  }
}

.table-pagination {
  .item {
    border: 0.0625rem solid $Primary !important;

    color: $Primary !important;
    border-radius: 50% !important;

    &.active {
      border: 0 !important;

      &:hover {
        background-color: $Primary !important;
        color: $white !important;
      }

      background-color: $Primary !important;
      color: $white !important;
    }
  }
}

.sub-data-table {
  background: #fafafa;
}
.custom-search-comp-mob {
  margin-top: 40px;
  margin-left: -10px;
}
.custom-row-app-home {
  margin-top: 25px;
}

.over-ride-segment-custom-mobile {
  .over-ride-segment-custom {
    .ui.segment {
      height: 72px;
      border-radius: 4px;
      border: 0.5px solid #d6d6d6;
      background: #fff;
      margin-top: 30px;
      margin-bottom: 30px;

      /* Card Shadow */
      box-shadow: 0px 2px 6px 0px rgba(210, 210, 210, 0.5);
      margin-bottom: 10px !important;
    }
  }
}

.override-custom-search-bar {
  .ui.input > input {
    height: 32px;
    border-radius: 4px;
    border: 0.5px solid #c3d3e5;
    background: #fff;
  }
  @media (max-width: 768px) {
    border-radius: 4px;
    border: 0.5px solid #c3d3e5;
    background: #fff;
    .ui.input > input {
      width: 300px !important;
    }
  }
}

.override-custom-search-bar-disabled {
  .ui.input > input {
    height: 32px;
    border-radius: 4px;
    border: 0.5px solid #c3d3e5;
    background: #fff;
    width: 330px;
    cursor: default;
    pointer-events: none;
    opacity: 0.45;
  }
}

.custom-button-add {
  border-radius: 4px !important;
  background: #000 !important;
  border-radius: 4px !important;
  background: #000 !important;
  color: $white !important;
  height: 40px !important;
  width: 180px !important;
  font-size: 14;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  div:last-of-type {
    margin-left: auto;
  }
}

.label-search-gap {
  margin-right: 20px;
}

.segment-title-custom {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}

.ui.basic.table {
  border: 1px solid rgba(34, 36, 38, 0.15) !important ;
}

.dashboard-icons {
  .ui.mini.image,
  .ui.mini.images .image,
  .ui.mini.images img,
  .ui.mini.images svg {
    width: 36px !important;
    height: 36px !important;
  }
}

.inverted-css {
  background-color: $black !important;
}

.welcome-note {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
.welcome-box-date {
  color: #056be7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.segment-title-label {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 144.444% */
}

.content-segment {
  padding: 0.75rem 0rem 0.75rem 0rem !important;
  width: 100% !important;
}

.dropdown-customer-custom {
  margin-left: 10px;
  display: flex;
  align-items: center;
  height: 32px;
  .ui.selection.dropdown {
    min-width: 100px !important;
    min-height: 32px !important;
    line-height: 8px !important;
    height: 32px !important;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: -1px;

    .ui.selection.dropdown {
      width: 300px !important;
    }
  }
  .ui.toggle.checkbox label:before {
    width: 3rem;
  }

  .ui.toggle.checkbox input:checked ~ label:after {
    left: 21px;
  }
}

.toggle-customer-custom {
  display: flex;
  align-items: center;
  height: 32px;
  .ui.selection.dropdown {
    min-width: 100px !important;
    min-height: 32px !important;
    line-height: 8px !important;
    height: 32px !important;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -1px;
  }
  .text-disabled {
    margin-left: 5px;
  }
}
.toggle-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--Color-Usage-Text-Secondary, #757575);
  margin-left: 5px;
  display: flex;
  align-items: center;
  height: 30px;
}

.empty-container-applications {
  .ui.segment:last-child {
    margin-bottom: 0;
    height: 674px;
    width: auto;
    background: $white;
  }
}

.custom-dropdown-button {
  .ui.labeled.icon.button {
    background-color: #000000;
    color: #ffffff;
  }
}
.content-segment-custom {
  padding: 0rem 0rem 0rem 0rem !important;
  border: 0px !important;
  box-shadow: none !important;
}

.validation-error-msg {
  color: #d50000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.validation-error-msg {
  color: #d50000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.lodged-button {
  background-color: #f59e0b !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: 6px !important;
  border-radius: 4px !important;
  gap: 10px !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: white !important;
  &:hover {
    background-color: #f59e0b !important;
    color: white !important;
  }
}

.save-button {
  background-color: $black !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: 6px !important;
  border-radius: 4px !important;
  gap: 10px !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: white !important;
  &:hover {
    background-color: #000000 !important;
    color: white !important;
  }
}

.application-recent-layout {
  .ui.grid > .row {
    padding-bottom: 10px !important;
  }
  .createdAt {
    color: #767676;
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .custom-pagination .ui.pagination.menu {
    flex-direction: row !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.right-image-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.right-image {
  max-width: 100%;
  height: auto;
}

.sign-in {
  display: flex !important;
  justify-items: center !important;
}

.card-container-flex-row {
  display: flex;
  flex-direction: row;
}

.card-image-icon .fixed-image-size {
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
}

/**** RESPONSIVE DESIGN ****/

// 2800
@media screen and (max-width: 2800px) {
  // .ui.icon.input > input {
  //   width: 400px;
  // }

  // .application-list-view
  //   .ui.header.application-list-header
  //   .drop-down-element
  //   .ui.selection.dropdown {
  //   min-width: 200px !important;
  // }

  .cus-name {
    margin-left: -40px;
  }
}

// 2300
@media screen and (max-width: 2300px) {
  // .ui.icon.input > input {
  //   width: 300px;
  // }

  // .application-list-view
  //   .ui.header.application-list-header
  //   .drop-down-element
  //   .ui.selection.dropdown {
  //   min-width: 200px !important;
  // }

  .cus-name {
    margin-left: -20px;
  }
}

// 1900
@media screen and (max-width: 1900px) {
  // .ui.icon.input > input {
  //   width: 200px;
  // }

  // .application-list-view
  //   .ui.header.application-list-header
  //   .drop-down-element
  //   .ui.selection.dropdown {
  //   min-width: 150px !important;
  // }
}

// 1720
@media screen and (max-width: 1720px) {
  .cus-name {
    margin-left: 0px;
  }
}

// 1400
@media screen and (max-width: 1400px) {
  .application-list-view .right-heading-container {
    padding-left: 30px !important;
  }
}

// 1200
@media screen and (max-width: 1200px) {
  .cus-name {
    padding-left: 15px;
  }
}

// 900
@media screen and (max-width: 900px) {
  .right-heading-container {
    padding-left: 10px !important;
  }
}

// 768
@media only screen and (max-width: 768px) {
  .ui.grid .ui.stackable.grid {
  }

  .flex-row {
    flex-direction: column;
  }

  .cus-name {
    padding-left: 0px;
  }
}

.date-picker {
  .css-160unip {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 50px !important;
  }

  .css-4jnixx-MuiStack-root {
    overflow: hidden !important;
    padding-top: 0px !important;
  }

  .MuiTextField-root {
    width: 315px !important;
    height: 40px;
    min-width: fit-content !important;

    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 40px !important;
  }
}

@media screen and (max-width: 821px) {
  .button-padding-controller {
    margin-left: 80px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 850px) {
  .button-padding-controller {
    margin-left: 80px;
    margin-top: 10px;
  }
}

.welcome-controller {
  @media screen and (max-width: 1000px) {
    padding-bottom: 20px;
  }
}

.application-controller {
  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
}

.mobile-view {
  display: flex;
  flex-direction: row;
}

.make-model-segment {
  margin-top: 9px !important;
  margin-bottom: 0px !important;
}

.cus-search-bar {
  .ui.input {
    width: 100%;
  }
}

.make-model-segment {
  .ui.search > .results {
    max-height: 300px;
    overflow-y: scroll;
    color: #000000;
    font-family: Inter;
    font-size: 12px;
    text-align: left;
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.compliance-title {
  padding-left: 15px;
  padding-top: 15px;
}

.step-indicator {
  padding-right: 15px;
}
.custom-check-box-container {
  display: flex;
  align-items: center;
}
.vehicle-year{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ef4444;
  margin-left:30px;
}
.terms-checkbox {
  padding-top: 5px;
}

.button-container Button {
  margin-left: 16px;
}
.terms {
  padding-right: 10px;
  padding-top: 5px;
}
